<template>
  <Form
    :initial-values="user"
    :validation-schema="validationSchema"
    method="put"
    action="/users"
    :param="user.id"
    @success="handleSuccess"
  >
    <div
      class="grid grid-cols-3 gap-4 mt-8 mb-6 font-semibold w-7/8 max-w-screen-2xl font-poppins"
    >
      <Field name="firstName" label="First Name" placeholder="Jane " />
      <Field name="lastName" label="Last Name" placeholder="Smith" />
      <Field name="email" label="Email" placeholder="jane.smith@csmgroup.com" />
      <Field name="phoneNumber" label="Phone Number" placeholder="6165551212" />
      <div>
        <RoleSelect :readonly="user.roleId !== 1" :modelValue="user.roleId">
          <template #label="{ fieldName, label }">
            <label v-if="user.roleId !== 1" :for="fieldName" class="label"
              >{{ label }}
              <lock-icon
                class="w-4 h-4 inline-block text-gray-500 ml-2 -mt-1"
              />
            </label>
          </template>
        </RoleSelect>
        <p
          v-if="user.roleId !== 1"
          class="open-sans text-gray-500 italic text-sm font-normal mt-1 w-1/2"
        >
          To change your role, contact your System Administrator
        </p>
      </div>
    </div>
    <h2 class="mt-10 text-2xl font-bold font-poppins">Notification Settings</h2>
    <div class="flex mt-10 space-x-8">
      <CheckboxButton
        label="System"
        description="Receive notifications in the application."
        name="systemNotifications"
      />
      <CheckboxButton
        label="Email"
        description="Send notifications to my email."
        name="emailNotifications"
      />
    </div>
    <Button
      label="Save Changes"
      variant="primary"
      class="w-64 my-12 font-bold font-poppins"
    />
  </Form>
</template>

<script>
import { object, string, number } from 'yup';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const { user }  = store.state.user;

    const validationSchema = object({
      email: string().required().email(),
      firstName: string().required(),
      lastName: string().required(),
      phoneNumber: string().required(),
      roleId: number().integer().required(),
    });

    function handleSuccess(message) {
      toast.show(message.message);
      store.dispatch('user/initialize');
      user();
    }

    return {
      validationSchema,
      user,
      handleSuccess,
    };
  },
};
</script>

<style lang="postcss" scoped>
.checkbox-button input:checked + div {
  @apply border-blue-300;
}
.checkbox-button input + div .icon {
  @apply hidden;
}
.checkbox-button input:checked + div .icon {
  @apply block;
}
</style>
