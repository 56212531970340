<template>
  <Modal id="update-password" :title="title" :close-action="handleClose">
    <template #default="{ close }">
      <p v-if="success" class="font-medium text-center">
        Your password has been updated successfully.
      </p>
      <div v-else>
        <Form
          class="mt-4 space-y-6"
          :validationSchema="validationSchema"
          dispatch="user/updatePassword"
          @success="handleSuccess"
        >
          <Field
            name="currentPassword"
            placeholder="Current Password"
            label="Current Password"
            type="password"
          />
          <Field
            name="newPassword"
            placeholder="New Password"
            label="New Password"
            type="password"
          />
          <Field
            name="confirmPassword"
            placeholder="Confirm Password"
            label="Confirm Password"
            type="password"
          />
          <div class="text-center">
            <Button class="mr-2">Save Password</Button>
            <Button class="ml-2 w-1/3" variant="secondary" @click="close"
              >Cancel</Button
            >
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script>
import { computed } from 'vue';
import { object, string, ref } from 'yup';
import { useState } from '@/helpers/composables';

export default {
  setup() {
    const [success, setSuccess] = useState(false);

    const title = computed(() =>
      success.value ? 'Password updated' : 'Update password'
    );

    function handleSuccess() {
      setSuccess(true);
    }

    function handleClose(done) {
      setSuccess(false);
      done();
    }

    const validationSchema = object({
      currentPassword: string().min(8).required(),
      newPassword: string().min(8).required(),
      confirmPassword: string()
        .min(8)
        .required()
        .oneOf([ref('newPassword'), null], 'passwords do not match.'),
    });

    return {
      validationSchema,
      success,
      handleSuccess,
      title,
      handleClose,
    };
  },
};
</script>
