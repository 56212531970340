<template>
  <span class="dropdown-item" v-bind="bindProps">
    <component
      :is="item.component"
      v-if="renderFromComponent"
      v-bind="componentProps"
    >
      {{ render }}
    </component>
    <button v-else-if="renderFromFunction" v-bind="componentProps">
      {{ render }}
    </button>
    <button v-else v-bind="componentProps">
      {{ item.label }}
    </button>
  </span>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  /* eslint-disable no-unused-vars */
  setup(props) {
    const render = computed(() => {
      return (
        props.item.render &&
        props.item.render({
          item: props.item,
        })
      );
    });

    const dynamicProps = computed(() => {
      return (
        props.item.$props &&
        props.item.$props({
          item: props.item,
        })
      );
    });

    const bindProps = computed(() => {
      const { label, render, $props, to, component, ...obj } = props.item;
      return obj;
    });

    const componentProps = computed(() => {
      const { label, render, component, ...obj } = props.item;
      return dynamicProps.value ? dynamicProps.value : obj;
    });

    const renderIsFunction = computed(() => {
      return typeof props.item.render === 'function';
    });

    const renderFromComponent = computed(
      () => renderIsFunction.value && props.item.component
    );

    const renderFromFunction = computed(
      () => renderIsFunction.value && !props.item.component
    );

    return {
      render,
      bindProps,
      componentProps,
      renderFromComponent,
      renderFromFunction,
    };
  },
};
</script>

<style lang="postcss" scoped>
.dropdown-item {
  @apply block
    my-2 
    cursor-pointer;
}
.dropdown-item > * {
  @apply text-gray-700
    whitespace-nowrap
    hover:text-blue-500
    transition-all
    ease-out
    duration-300;
}
button:disabled {
  @apply opacity-50 hover:text-gray-700 cursor-default;
}
</style>
