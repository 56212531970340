<template>
  <div v-click-outside="handleClose" class="dropdown">
    <slot
      name="trigger"
      :handleToggle="handleToggle"
      :handleOpen="handleOpen"
      :handleClose="handleClose"
    />
    <transition name="fade">
      <div v-if="open" class="dropdown-items">
        <DropdownItem
          v-for="(item, index) in items"
          :key="index"
          :item="item"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import DropdownItem from './DropdownItem.vue';
import { useState } from '@/helpers/composables';

export default {
  components: {
    DropdownItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const [open, setOpen] = useState(false);

    function handleToggle() {
      setOpen(!open.value);
    }

    function handleOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    return {
      handleToggle,
      handleOpen,
      handleClose,
      open,
    };
  },
};
</script>

<style lang="postcss" scoped>
.dropdown {
  @apply relative;
}
.dropdown-items {
  @apply shadow-md
    absolute
    top-full
    right-0
    bg-white 
    px-4 
    border
    border-gray-300
    rounded
    z-10
    mt-1;
}
</style>
