<template>
  <div class="h-full min-h-full px-4 pt-16 bg-white lg:px-16">
    <div class="flex justify-between">
      <h1 class="text-4xl font-normal font-poppins">{{ fullName }}</h1>
      <Dropdown :items="actionItems">
        <template #trigger="{ handleToggle }">
          <Button
            @click="handleToggle"
            variant="primary"
            class="w-48 font-bold font-poppins"
          >
            Actions
            <template #append>
              <angle-down-icon class="text-black w-4 h-4" />
            </template>
          </Button>
        </template>
      </Dropdown>
    </div>
    <h2 class="mt-16 text-2xl font-bold font-poppins">User Details</h2>
    <AccountForm />
    <UpdatePasswordModal />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import AccountForm from '@/components/forms/AccountForm.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import UpdatePasswordModal from '@/components/overlays/modals/UpdatePasswordModal.vue';
import { useModal, useToast } from '@/helpers/composables';

export default {
  components: {
    AccountForm,
    Dropdown,
    UpdatePasswordModal,
  },
  setup() {
    const store = useStore();
    const { user } = store.state.user;
    const fullName = computed(() => store.getters['user/fullName']);
    const { open: openUpdatePasswordModal } = useModal('update-password');
    const toast = useToast();

    const actionItems = ref([
      {
        label: 'Update Password',
        onclick: (event) => {
          event.stopPropagation();
          openUpdatePasswordModal();
        },
      },
      {
        label: 'Reset Password',
        onclick: async (event) => {
          event.stopPropagation();
          const { email } = user;
          const { message } = await store.dispatch('user/forgotPassword', {
            formData: { email },
          });
          toast.show(message);
        },
      },
    ]);

    return {
      user,
      fullName,
      actionItems,
    };
  },
};
</script>
